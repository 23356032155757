import Footer from "~/components/Footer";
import { FooterNavigation } from "~/components/FooterNavigation";
import { Header } from "~/components/Header";
import { Sidebar } from "~/components/Sidebar"
import { Scripts } from "remix";
import { useResponsive } from '../hooks/useResponsive';
import { LoaderFunction } from 'remix';
import { SliderContent } from "~/components/SliderContent";

export const loader: LoaderFunction = async () => {
    return {};
};

export const DefaultLayout: React.VFC<Props> = ({ children }) => {
    const { isMobile, isDesktop } = useResponsive();
    return (
        <>
            <body>
                <Header />
                <SliderContent />
                <div className="content">
                    <div className="contentMain defaultWrapper">
                        {children}
                    </div>
                    {isDesktop && <Sidebar />}
                </div>
                <Footer />
                {isMobile && <FooterNavigation />}
                <Scripts />
            </body>
        </>
    );
};

